import React from 'react';
import Loadable from 'react-loadable';
import SectionPlatform from '../partials/_SectionPlatform';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import searchPreviewData from '../../../../data/previews/search-input.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const SearchInput = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.SearchInput;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Search Input"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Forms"
        tierThree="Search Input"
      />
      <V5Notice
        componentName="Search Input"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-inputs--docs"
      />

      <PageNavigation
        links={[
          'Size',
          'Mobile',
          'Usage',
          'Microcopy',
          'Accessibility',
          'Platform',
        ]}
      />

      <Section title="Size">
        <Paragraph>
          Size should be determined by the amount of white space in the
          interface the search input will be used in.
        </Paragraph>
        <ComponentPreview
          name="SearchSize"
          layout="halfWidth"
          previewData={searchPreviewData.sizes}>
          <SearchInput placeholder="Find an opponent" />
        </ComponentPreview>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Stick to using native components when designing search for mobile and
          display the results in table or list views.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Labels</SectionSubhead>
        <Paragraph>
          Labels are <strong>mandatory</strong> on all fields, but they don’t
          always have to be visible. This is especially true for search, so make
          use of hidden labels as they make a difference in accessibility.
        </Paragraph>

        <SectionSubhead>Layout</SectionSubhead>
        <Paragraph>
          Search should appear only once in an interface and relate to the
          content it’s presented with.
        </Paragraph>
        <DontDo
          dontText="exclude search from the context of the interface."
          doText="keep search relevant to the user’s workflow."
          imgFilename="searchinput-context"
        />

        <SectionSubhead>Results</SectionSubhead>
        <Paragraph>
          Results should always relate to the information input by the user and
          never take them out of context. If a basketball team is searching for
          opponents in the area, results shouldn’t return all opponents in every
          sport.
        </Paragraph>
        <DontDo
          dontText="ignore key words used and return irrelevant information."
          doText="treat search as a way to discover content."
          imgFilename="searchinput-irrelevant-info"
        />
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Placeholders</SectionSubhead>
        <Paragraph>
          Placeholders are <strong>required</strong> and should use verbs to
          suggest what the results will return.
        </Paragraph>
        <DontDo
          dontText="use placeholders in place of hidden labels."
          doText="provide instruction with an appropriate action."
          imgFilename="searchinput-placeholder"
        />
        <Paragraph>
          They should follow{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            non-label text guidelines
          </Link>
          , but don’t put punctuation at the end.
        </Paragraph>
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Forms / Search Input" />

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
